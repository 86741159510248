import { useEffect, useState } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import '../../App.css';
import _ from 'lodash';

const Home = () => {
  const [status, setStatus] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const value = queryString.parse(window.location.search);
    const token = value.token;

    if (!_.isEmpty(token)) {
      axios
        .post('/v1/auth/confirmation', { token })
        .then((response) => {
          const { data } = response;
          const temp = data?.error?.status ?? true;

          setStatus(temp);
          setError(false);
        })
        .catch((err) => {
          setError(true);
        });
    } else setError(true)
  }, []);

  return (
    <div className="App">
      {status && <h1>Welcome to join with Us</h1>}
      {!status && <div>
        <p>Your LuvLink™ account has been activated. Please login to your account via the app.</p>
        <p>Any questions please visit our help centre - <a href="https://help.luvlink.com">https://help.luvlink.com</a></p>
      </div>}

      {error && (
        <h2 style={{ color: 'red' }}>
          Your account is not active. Please try again!
        </h2>
      )}
    </div>
  );
}

export default Home