import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Approval from "./pages/Approval";
import Home from "./pages/Home";
import ResetPassword from "./pages/ResetPassword";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/approval">
          <Approval />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
